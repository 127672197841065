body {
  margin: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*----------------------------------------
----------------------------------------*/




body {
  font-family: 'Encode Sans Expanded', sans-serif;
  font-family: 'Instrument Serif', serif;
  font-family: 'Encode Sans Expanded', sans-serif;
  font-family: 'Poppins', sans-serif;
  background-color: var(--primary-Background) !important;
}

*::-webkit-scrollbar {
  width: 8px;
  height: 5px;
  border-radius: 10px;
}

.submenuItems::-webkit-scrollbar {
  width: 5px;
}

*::-webkit-scrollbar-track {
  background: #eef7ff;
}

*::-webkit-scrollbar-thumb {
  background: var(--primary);
  border-radius: 10px;
}

.main-panel::-webkit-scrollbar {
  width: 0px !important;
  height: 0px;
  border-radius: 10px;
}

.main-panel::-webkit-scrollbar-track {
  background: #eef7ff;
}

.main-panel::-webkit-scrollbar-thumb {
  background: var(--primary);
  border-radius: 10px;
}

tbody {
  background-color: #eaeaf1 !important;
  border-radius: 10px;
}

.card-bg {
  background: #E9E9E9 !important;
}

.add_circle {
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
}

.side_search input ,.side_search input::placeholder ,.side_search input:focus[type="text"]{
  background-color: #fff !important;
  height: 35px;
  color: #000000;
    font-size: smaller;
}
.icon_size{
   height: 30px !important;
   width: 30px !important;
}
.border_error{
  border: 1px solid #e82222 !important;
}

.css-1ycxvqq-MuiSwitch-root .MuiSwitch-switchBase.Mui-checked{
   color: var(--primary) !important;
}
.css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track{
   background-color: #8c6bef !important;
}
.div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm{
   background-color: var(--primary) !important;
}

.fs_12{
font-size: 12px;
}

._description{
  white-space: break-spaces;
  line-height: 1.38;
}

.eventProgram:hover {
  background-color: #8872cb;
  color: #fff;

}