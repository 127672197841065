.login {
  background: #cec7e3;
}
.login-form {
  border-radius: 18px;
  background: #fff;
  /* box-shadow: 8px 8px 40px 0px #9b92b8; */

  width: 400px;
  height: 500px;
  flex-shrink: 0;
}

.login-heading p {
  color: #785ad2;
  text-align: center;
  font-family: Encode Sans Expanded;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -1.2px;
}

.login-form .form-outline input {
  width: 326px;
  height: 62px;
  flex-shrink: 0;
  border-radius: 20px;
  border: 0px solid #000;
  background: #e9e9e9;
  backdrop-filter: blur(2px);
  padding-left: 28px;
}

.login-form .form-outline input::placeholder {
  color: #8f8f8f;
  font-family: Encode Sans Expanded;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.text-end a {
  color: #545454;
  font-family: Encode Sans Expanded;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.login-form .btn.btn-primary.btn-block.mb-4 {
  color: #fff;
  font-family: Encode Sans Expanded;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border-radius: 20px;
  border: 0px solid #000;
  background: #8872cb;
  backdrop-filter: blur(2px);
  width: 326px;
  height: 62px;
  line-height: 47px;
}

.dark_mode .login-form {
  background-color: var(--dark_sidebar) !important;
  color: #ced4da !important;
} 
.dark_mode .login-form input {
  border: 1px solid rgb(55 65 81) !important;
}


