.drag-card {
  border-radius: 0;
}

.drag-section {
  padding: 0 !important;
}

.drop-container {
  overflow: auto;
  min-height: 100px;
  /* border: 1px dashed #ccc; */
  border-radius: 4px;
  background-color: #f8f9fa;
  transition: all 0.2s ease;
  padding: 1rem;
}

.drop-container-title {
  background-color: #d7d9db;
  padding: 0.5rem 1rem;
  margin-bottom: 0;
}

.drag-item {
  background: #f8f9fa;
  border: 1px dashed #ccc;
  /* background: #d7d9db; */
  cursor: pointer;
  padding: 8px 16px;
  border-radius: 4px;
  margin-bottom: 8px;
  user-select: none;
  transition: all 0.2s ease;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.drag-item:hover {
  border: 1px dashed #7530cd;
  background-color: #f5f7ff;
}

.drag-item:active {
  background-color: #e9e1ff;
}
