.clear-media-btn {
  top: -8px;
  right: -8px;
  position: absolute;
}

.clear-media-btn i {
  font-size: 11px;
}

.attachments__container {
  display: flex;
  gap: 16px;
}

.group__card {
  display: flex;
  align-items: center;
  gap: 8px;
}

.group__card p {
  font-size: 13px;
}

.group__card--media img {
  width: 40px;
  height: 40px;
}

.group__card--info {
  width: calc(100% - 40px - 40px);
}

.group__card--action i {
  font-size: 13px;
}
