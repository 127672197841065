.drop ul li:hover {
    background: var(--primary);
    color: #fff;
    cursor: pointer;
}

.color{
    color: #000 !important;
}
.dark_mode .color{
    color: #fff !important;
}