.nav-link {
    cursor: pointer;
  }
  
  .nav-link.active {
    background-color: var(--primary);
    color: #ffffff !important;
  }
  

  .nav-sidebar > .nav-item > .nav-link {
    color: rgb(255, 255, 255);
  }
