.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} 
 
.form-check-input.checkbox {
  position: relative;
  margin-top: 6px;
  }

  a{
    text-decoration: none !important; 
  }

  .cursor{
    cursor: pointer;
  }
  .load{
    opacity: 0.5;
  }
  
  .dashboard-loader{
    height: 90%;
    width:80%;
    display:flex;
    justify-content:center;
    align-items:center;
    position:absolute;
    z-index:1000;
  }

  .error{
    font-size: small;
    font-weight: bold;
    color:rgb(255, 82, 82);
    text-align: left;
  }

  .ck.ck-editor__editable_inline {
    text-align: left;
    min-height: 80px;
}

.CommonImage{
  width: 200px;
  height:200px;
  border-radius: 10px;
  box-sizing: border-box;
}

/* .table:not(.table-dark) {
  color: lightgray;
} */


.content-wrapper { 
  padding: 1.375rem 0.375rem !important;
}

.breadcrumb {
  border: none;
}

.modal .modal-dialog {
  margin-top: 25px !important;
}

.modal .modal-dialog .modal-content .modal-header {
  padding: 15px 26px !important;
}

.modal .modal-dialog .modal-content .modal-footer {
  padding: 5px 15px !important;
}


.navbar {   
  -webkit-box-shadow: none !important; 
  box-shadow: none !important;
}

.table td{
  padding: 0.45em 0.375rem !important;
  line-height: 2;
}

.LoaderSpinner{
  position: fixed; 
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.623);  
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; 
}
.LoaderSpinner div{
  position: absolute;
  margin-top: 100px;
}
 