.bg-card1{
    background-color: #a573ff;
    color: white;
}

.bg-card2{
    background-color: #17a2b8;
    color: white;
}

.bg-card3{
    background-color: #3498db;
    color: white;
}

.bg-card4{
    background-color: #003aff;
    color: white;
}

.MuiDataGrid-cell:focus {
    outline: none !important;
  }

  .MuiDataGrid-columnHeader:focus {
    outline: none !important;
  }

  /* .chart-legend-label {
    margin-bottom: 20px; 
  } */